<template>
  <div class="main-box">
    <a-breadcrumb>
      <a-breadcrumb-item><a @click="$router.go(-1)">{{$route.query.from}}</a></a-breadcrumb-item>
      <a-breadcrumb-item style="color: #15b7dd">{{$route.query.type}}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="play-inner">
      <div class="Player">
        <div class="title">
          <p>{{detail.articleTitle}}</p>
        </div>
        <video class="video" controls="controls" controlsList="nodownload" autoplay="true" :src="detail.videoLink"></video>
      </div>
      <!-- <div v-html="detail.mobileContent"></div> -->
      <div class="lable">
        <span v-for="(label,index) in fun_split(detail.label)" :key="index">#{{label}}</span>
      </div>

      <div class="other-video">
        <p class="title">其他推荐</p>
        <swiper
          class="arrange"
          :options="swiperOptiont"
        >
          <swiper-slide
            class="item-levels" v-for="item in SPVideoList" :key="item.id"
          >
            <div @click="onCutVideo(item)">
              <img class="cover" :src="item.banner" alt="">
              <p class="name mallHidden">{{item.articleTitle}}</p>
              <div class="lable">
                <span v-for="(label,index) in fun_split(item.label)" :key="index">#{{label}}</span>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      swiperOptiont: {
        slidesPerView: "auto",
        spaceBetween: 18,
        centeredSlides: false,
        centeredSlidesBounds: false,
      },
      id:0,
      detail:{}, // 视频详情
      SPVideoList:[], // 科普视频
    }
  },
  // 事件处理器
  methods: {
    // 格式化标签
    fun_split(str){
      let arr = []
      if(!str){return arr}
      arr = str.split("，")
      return arr
    },

    // 切换视频
    onCutVideo(e){
      this.detail = e
      this.getVolume(e.id)
    },

    // 获取科普视频详情
    getVideoDetail() {
      this.$ajax({
        url: "/hxclass-pc/Article/detail/" + this.id,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getVolume(this.id)
          this.detail = res.data
        }
      });
    },

      // 增加点击量
    getVolume(id) {
      this.$ajax({
        url: "/hxclass-pc/Article/up/" + id,
      }).then((res) => {});
    },

    // 获取其他科普视频
    getClient() {
      this.$ajax({
        url: "/hxclass-pc/Article/client/list",
        params: {
          columnCode: 'SPVideo',
          queryLevel: 1,
          sortType: 1,
          pageNum: 1,
          pageSize: 100,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.SPVideoList = res.data.records
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.id = this.$AES.decode_data(this.$route.query.id);
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.getClient()

    this.getVideoDetail()
   },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  .main-box{
    padding-top: 80px;
    padding-bottom: 50px;
    .play-inner{
      margin-top: 15px;
      .Player{
        position: relative;
        .title{
          position: absolute;
          transition: all 0.4s ease;
          opacity: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 57px;
          background: linear-gradient(180deg, #000000 0%, rgba(0,0,0,0) 100%);
          p{
            margin-top: 12px;
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 30px;
            margin-left: 16px;
          }
        }
        .video{
          width: 100%;
          max-height: 600px;
        }
      }
      .Player:hover{
        .title{
          opacity: 1;
        }
      }

      .lable{
        margin-top: 16px;
        span{
          margin-right: 10px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          line-height: 21px;
        }
      }

      .other-video{
        margin-top: 36px;
        .title{
          font-size: 22px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 33px;
        }
        .arrange{
          margin-top: 20px;
          display: flex;
          .item-levels{
            padding: 14px;
            width: 146px;
            min-height: 184px;
            background: #FFFFFF;
            margin-right: 42px;
            transition: all 0.4s ease;
            cursor: pointer;
            .cover{
              width: 100%;
              height: 73px;
              margin-bottom: 12px;
              background-repeat: no-repeat;
              object-fit: cover;
              transition: transform 0.5s;
            }
            .name{
              height: 42px;
              font-size: 14px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 21px;
            }
            .lable{
              margin-top: 13px;
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #15B7DD;
              line-height: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              >span{
                margin-right: 5px;
              }
            }
          }
          .item-levels:hover{
            box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
            .cover{
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }

</style>
